import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentNavigationBar.figmaUrl.android} codeUrl={checklists.componentNavigationBar.codeUrl.android} checklists={checklists.componentNavigationBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Navigation Bar or NavBar is an embedded name to define a navigation system that is collected in one section on the Android display. The shape, which is generally long in shape with a title, makes designers call it a bar (beam).`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/Navbar_component.gif",
            "alt": "spinner android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 6 variants for NavBar :`}</p>
    <ol>
      <li parentName="ol">{`Navbar Title`}</li>
      <li parentName="ol">{`Navbar Title and Description`}</li>
      <li parentName="ol">{`Navbar Title Large`}</li>
      <li parentName="ol">{`Navbar Title and Description Large`}</li>
      <li parentName="ol">{`Navbar Search Bar`}</li>
      <li parentName="ol">{`Navbar Search Bar with Title`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement NavBar , you can define in xml or you can set the attribute programmatically.`}</p>
    <h3>{`1. Navbar Title`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnTitleNavBar
    ...
    app:title="@string/label_navbar_sample_title"
    app:menu="@menu/navbar_menu"/>


`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitle.apply {
    title = "Title"
    menu = R.menu.navbar_menu
}
...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitle.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
}
...
`}</code></pre>
    <h3>{`2. Navbar Title and Description`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnTitleDescNavBar
    ...
    app:title="@string/label_navbar_sample_title"
    app:description="@string/label_navbar_sample_description"
    app:menu="@menu/navbar_menu"/>


`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleDesc.apply {
    title = "Title"
    description = "Description"
    menu = R.menu.navbar_menu
}

...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleDesc.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
}

...
`}</code></pre>
    <h3>{`3. Navbar Title Large`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnTitleLargeNavBar
    ...
    app:title="@string/label_navbar_sample_title"
    app:menu="@menu/navbar_menu"/>



`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleLarge.apply {
    title = "Title"
    menu = R.menu.navbar_menu
}


...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleLarge.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
}

...
`}</code></pre>
    <h3>{`4. Navbar Title and Description Large`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnTitleDescLargeNavBar
    ...
    app:title="@string/label_navbar_sample_title"
    app:description="@string/label_navbar_sample_description"
    app:menu="@menu/navbar_menu"/>



`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleDescLarge.apply {
    title = "Title"
    description = "Description"
    menu = R.menu.navbar_menu
}

...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarTitleDescLarge.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
}

...
`}</code></pre>
    <h3>{`5. Navbar Search Bar`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnSearchNavBar
    ...
    app:placeholder="@string/label_navbar_sample_placeholder"
    app:menu="@menu/navbar_menu"
    app:startIconDrawable="@drawable/ic_clock"
    app:endIconDrawable="@drawable/ic_eye_on"/>

`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarSearchBar.apply {
    placeholder = "Placeholder"
    startIconDrawable = ContextCompat.getDrawable(context, R.drawable.startIcon)
    endIconDrawable = ContextCompat.getDrawable(context, R.drawable.endIcon)
    imeOptions = EditorInfo.IME_ACTION_SEARCH
    menu = R.menu.navbar_menu
}


...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarSearchBar.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
    setOnStartIconTextFieldListener(listener)
    setOnEndIconTextFieldListener(listener)
}


...
`}</code></pre>
    <h3>{`6. Navbar Search Bar with Title`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.navbar.LgnTitleSearchNavBar
    ...
    app:title="@string/label_navbar_sample_title"
    app:placeholder="@string/label_navbar_sample_placeholder"
    app:menu="@menu/navbar_menu"
    app:startIconDrawable="@drawable/ic_clock"
    app:endIconDrawable="@drawable/ic_eye_on"/>


`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarSearchBarTitle.apply {
    title = "Title"
    placeholder = "Placeholder"
    startIconDrawable = ContextCompat.getDrawable(context, R.drawable.startIcon)
    endIconDrawable = ContextCompat.getDrawable(context, R.drawable.endIcon)
    imeOptions = EditorInfo.IME_ACTION_SEARCH
    menu = R.menu.navbar_menu
}


...
`}</code></pre>
    <p>{`Set event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.navbarSearchBarTitle.apply {
    setOnMenuItemClickListener(listener)
    setOnNavigationClickListener(listener)
    setOnStartIconTextFieldListener(listener)
    setOnEndIconTextFieldListener(listener)
}

...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set and get title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set and get description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Menu Toolbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:menu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`menu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set menu action in toolbar. need to define menu xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set placeholder in edit text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set prefix icon in edit text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set suffix icon in edit text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ime Options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:imeOptions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`imeOptions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set ime options in edit text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit Text Value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`etValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set edittext value in edit text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Back Navigation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnNavigationClickListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set event back click listener`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Menu Navigation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnEndIconTextFieldListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set event menu item click listener`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Start Icon Edit Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnEndIconTextFieldListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set event start icon on edit text click listener`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event End Icon Edit Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnEndIconTextFieldListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set event end icon on edit text click listener`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Text Changed Edit Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnEditTextChangeListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set edit text changed on text listener`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      